export class ConstantVariables {
  
  public DOWNLOAD_MSG = 'Digital Download products can only be downloaded while you are logged into website on desktop/MAC.';
  public SHOP_DIGITAL_ERROR_MSG = `We've noticed that you've added more than three shop digital product items to your cart. We want to make sure this order is being made by you. Please give us a call at <b>9999999999</b> and we will complete your order.`;
  public SUIT_TYPE = [
    { name: 'APT', value: 'Apartment' },
    // { name: 'AVE', value: 'Avenue' },
    { name: 'Block', value: 'Block' },
    { name: 'FL', value: 'Floor' },
    { name: 'PMB', value: 'PMB' },
    { name: 'STE', value: 'Suite' },
    { name: 'Unit', value: 'Unit' },
  ];
  public SUIT_TYPE_UPDATE = [
    { name: 'APT', value: 'Apartment' },
    // { name: 'AVE', value: 'Avenue' },
    { name: 'Block', value: 'Block' },
    { name: 'FL', value: 'Floor' },
    { name: 'PMB', value: 'PMB' },
    { name: 'STE', value: 'Suite' },
    { name: 'Unit', value: 'Unit' },
    { name: 'Other', value: 'Other' },
  ];
  public DOWNLOAD_PRODUCT_CONFIRMATION_MSG = 'Do you want to download the digital product on your system?';

  public SHOP_DIGITAL_CONTENT = 'Shop Digital Products';
  public DIGITAL_PRODUCT = 'Digital Products';
  public SHOP_OFFICE_SUPPLIES = 'Shop Office Supplies';
  public OFFICE_SUPPLIES = 'Office Supplies';

  public ADD_DIGITAL_PRODUCT_ERROR = `You cannot add Digital Products to your shopping cart at the same time while you are purchasing Office Supplies.`;
  public ADD_OFFICE_SUPPLIES_PRODUCT_ERROR = `You cannot add Office Supplies to your shopping cart at the same time while you are purchasing Digital Products.`;
  public EMPTY_CART_MSG = `Use Your Net 30 tradeline to make your purchase. We report active Net 30 accounts to the credit bureau.`;
  public NET_30_ONLY_MSG = 'Use your Net 30 Tradeline in this section – Only.';
  public CREDIT_CARD_ONLY_MSG = 'Use your credit card to purchase Office Supplies - Only';
  public SEND_OTP_MSG =  `a four digit code to verify`;
  public SEND_OTP_MSG_PRE = `Click here to`;
  public MINIMUM_AMOUNT = `99.00`;
  public MINIMUM_OFFICE_SUPPLIES_AMOUNT = `100.00`;
  public MAXIMUM_OFFICE_SUPPLIES_AMOUNT = `500.00`;
  // public CHECKOUT_PAGE_MSG = `This is because Office Supplies is not shipped the same day. it takes anywhere around 3-7 business days for the office supplies to be delivered because as we know, the office supplies do not come from one warehouse`;
  public CHECKOUT_PAGE_MSG = `All paid Office Supplies orders takes around 3-7 days to be delivered.`;
}
